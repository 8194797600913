@font-face {
  font-family: 'Gilmer';
  font-display: swap;
  src: url('./assets/fonts/GilmerFont2/Gilmer-light.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gilmer';
  font-display: swap;
  src: url('./assets/fonts/GilmerFont2/Gilmer-Regular.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gilmer';
  font-display: swap;
  src: url('./assets/fonts/GilmerFont2/Gilmer-Medium.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Gilmer';
  font-display: swap;
  src: url('./assets/fonts/GilmerFont2/Gilmer-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilmer';
  font-display: swap;
  src: url('./assets/fonts/GilmerFont2/Gilmer-Heavy.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}
:root {
  /* Animations */
  --animation-duration: 0.5s;
  --animation-timing: ease;

  /* sizes */
  --spacing: 0;

  /* Fonts */

  /* Fonts: Line Height */

  /* Breakpoints */
  --breakpoints-mobile: 320px;
  --breakpoints-tablet: 768px;
  --breakpoints-computer: 992px;
  --breakpoints-desktop: 1200px;
  --breakpoints-widescreen: 1920px;

  /* Colors */
  --gray: #eeeeee;
  --green: #34a853;
  --green2: #ddf3e3;
  --red: #ea4336;
  --red2: rgba(234, 67, 54, 0.1);
  --yellow: #f1a85a;
  --yellow2: rgba(241, 168, 90, 0.1);
  --text-gray-1: #a5b0b7;
  --text-gray-2: #798892;
  --text-color: #001928;
  --text-card-color: #374b58;

  /* Borders */
  --borders-radius: 10px;

  /* Shadows */
  --shadows-subtle: 0px 1px 2px 0 var(--borders-color);
}

html,
body {
  padding: 0;
  margin: 0;
  background: #fff;
  font-family: Gilmer, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 2px;
  padding: 2px;
}
a {
  color: inherit;
  text-decoration: none;
}

